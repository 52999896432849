import {createStore} from 'vuex'

export default createStore({
  state: {
    auth: {
      isAuthenticated: false,
      token: ''
    }
  },
  getters: {},
  mutations: {
    setAuth(state, isAuthenticated) {
      state.auth.isAuthenticated = isAuthenticated;
    }
  },
  actions: {
    setAuth({ commit }, isAuthenticated) {
      commit('setAuth', isAuthenticated);
    }
  },
  modules: {}
})
