<template>
  <div class="dashboard-container">
    <!-- Sidebar Esquerda -->
    <nav class="sidebar dalipay-bg">
      <ul class="nav flex-column">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Dashboard</a>
        </li>
        <li class="nav-item">
          <router-link to="/relatorios"><a class="nav-link" href="#">Relatórios</a></router-link>
        </li>
        <li class="nav-item">
          <router-link to="/configuracoes"><a class="nav-link" href="#">Configurações</a></router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Ajuda</a>
        </li>
      </ul>
    </nav>

    <!-- Conteúdo à direita -->
    <main class="content">
      <!-- Header -->
      <header class="header d-flex justify-content-between align-items-center">
        <h1 class="logo"><span class="sitename"></span></h1>
        <div class="header-icons d-flex align-items-center">
          <button class="btn btn-outline-light" @click="logout">Logout</button>
        </div>
      </header>

      <!-- Conteúdo Principal - Blocos de Dados -->
      <div class="content-body">
        <div class="data-block-container">
          <!-- Quadrado Totais Pix -->
          <div class="data-block">
            <h5>Resumo Pix</h5>
            <p>Saldo disponível: R$ {{ totaisDash.resumoPix.saldoDisponivel }}</p>
            <p>Valores a receber: R$ {{ totaisDash.resumoPix.valoresReceber }}</p>
            <p>Total em transações: {{ totaisDash.resumoPix.totalTransacoes }}</p>
          </div>

          <!-- Quadrado Totais Cartão -->
          <div class="data-block">
            <h5>Resumo Cartão</h5>
            <p>Saldo disponível: R$ {{ totaisDash.resumoCartao.saldoDisponivel.toFixed(2) }}</p>
            <p>Valores a receber: R$ {{ totaisDash.resumoCartao.valoresReceber.toFixed(2) }}</p>
            <p>Total em transações: {{ totaisDash.resumoCartao.totalTransacoes }}</p>
          </div>

          <!-- Quadro de valores disponíveis para saque -->
          <div class="data-block">
            <h5>Disponível para saque</h5>
            <p>Saldo pix: R$ {{ totaisDash.saquesDisponiveis.totalPix }}</p>
            <p>Saldo cartão: R$ {{ totaisDash.saquesDisponiveis.totalCartao.toFixed(2) }}</p>
            <p>Total: R$ {{ totaisDash.saquesDisponiveis.total }}</p>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script lang="ts">
import { ref, onMounted } from "vue";
import axios from "axios";
import { useStore } from "vuex";
import router from "@/router";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Dashboard",
  setup() {
    const totaisDash = ref({
      resumoPix: {
        saldoDisponivel: 0,
        valoresReceber: 0,
        totalTransacoes: 0,
      },
      resumoCartao: {
        saldoDisponivel: 0,
        valoresReceber: 0,
        totalTransacoes: 0,
      },
      saquesDisponiveis: {
        totalPix: 0,
        totalCartao: 0,
        total: 0,
      },
    });

    const store = useStore();

    const fetchTotaisDashboard = async () => {
      try {
        const response = await axios.get("v1/admin/dashboard/dados");
        totaisDash.value = response.data;
      } catch (error) {
        console.error("Erro ao buscar totais do dashboard:", error);
      }
    };

    const logout = async () => {
      await store.dispatch("setAuth", false);
      localStorage.removeItem("jwt");
      await router.push("/");
    };

    onMounted(() => {
      fetchTotaisDashboard();
    });

    return {
      totaisDash,
      logout,
    };
  },
};
</script>

<style scoped>
.dashboard-container {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 250px;
  padding-top: 20px;
}

.dalipay-bg {
  background-color: #006b5b;
}

.sidebar .nav-link {
  color: white;
  margin: 10px 0;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.sidebar .nav-link.active,
.sidebar .nav-link:hover {
  background-color: #004f45;
}

.content {
  flex-grow: 1;
  padding: 20px;
  background-color: #f8f9fa;
}

.content-body {
  padding-top: 20px; /* Espaço entre o header e os blocos */
}

.data-block-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
  justify-content: center; /* Centraliza os blocos horizontalmente */
}

.data-block {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}


.header {
  background-color: #a7b9b1;
  color: white;
  padding: 15px;
  border-radius: 10px;
}

.logo {
  font-weight: bold;
  font-size: 1.5rem;
}

.data-block-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

.data-block {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.data-block h5 {
  font-weight: 600;
  margin-bottom: 15px;
}
</style>