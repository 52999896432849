<template>
  <div class="dashboard-container">
    <!-- Sidebar Esquerda -->
    <nav class="sidebar dalipay-bg">
      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link to="/dashboard"> <a class="nav-link" aria-current="page" href="#">Dashboard</a></router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link active" href="#">Relatórios</a>
        </li>
        <li class="nav-item">
          <router-link to="/configuracoes"><a class="nav-link" href="#">Configurações</a></router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Ajuda</a>
        </li>
      </ul>
    </nav>

    <!-- Conteúdo à direita -->
    <main class="content">
      <!-- Header -->
      <header class="header d-flex justify-content-between align-items-center">
        <h1 class="logo"><span class="sitename"></span></h1>
        <div class="header-icons d-flex align-items-center">
          <button class="btn btn-outline-light" @click="logout">Logout</button>
        </div>
      </header>

      <!-- Conteúdo Principal -->
      <div class="content-body">
        <!-- Tabela 1 - Transações Pix -->
        <div class="table-responsive table-scroll mb-4">
          <h5>Transações Pix</h5>
          <table class="table table-striped">
            <thead>
            <tr>
              <th>#</th>
              <th>ID Cobrança</th>
              <th>Criação</th>
              <th>Valor</th>
              <th>Status</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(pix, index) in transacoesPix" :key="pix.idCobranca">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ pix.idCobranca }}</td>
              <td>{{ new Date(pix.criacaoCobranca).toLocaleString() }}</td>
              <td>R$ {{ pix.valorCobranca.toFixed(2) }}</td>
              <td>
                  <span :class="{
                    'badge bg-success': pix.statusCobranca === 'CONCLUIDA',
                    'badge bg-warning': pix.statusCobranca === 'ATIVA',
                    'badge bg-danger': pix.statusCobranca === 'CANCELADA'
                  }">
                    {{ pix.statusCobranca }}
                  </span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <!-- Tabela 2 - Transações Cartão -->
        <div class="table-responsive table-scroll">
          <h5>Transações Cartão</h5>
          <table class="table table-striped">
            <thead>
            <tr>
              <th>#</th>
              <th>ID</th>
              <th>Hora Transação</th>
              <th>Valor</th>
              <th>Status</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(cartao, index) in transacoesCartao" :key="cartao.id">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ cartao.id }}</td>
              <td>{{ new Date(cartao.horaTransacao).toLocaleString() }}</td>
              <td>R$ {{ cartao.valor.toFixed(2) }}</td>
              <td>
                  <span :class="{
                    'badge bg-success': cartao.codigoRetorno === '00',
                    'badge bg-danger': cartao.codigoRetorno !== '00'
                  }">
                    {{ cartao.mensagemRetorno }}
                  </span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { ref, onMounted } from "vue";
import axios from "axios";
import {useStore} from "vuex";
import router from "@/router";
// Usando o axios com interceptor

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Relatorios",
  setup() {
    const transacoesPix = ref([]);
    const transacoesCartao = ref([]);
    const store = useStore();

    const fetchTransacoesPix = async () => {
      try {
        const response = await axios.get("v1/admin/cobranca/dados-pix");
        transacoesPix.value = response.data;
      } catch (error) {
        console.error("Erro ao buscar transações Pix:", error);
      }
    };

    const fetchTransacoesCartao = async () => {
      try {
        const response = await axios.get("v1/admin/cobranca/dados-cartoes");
        transacoesCartao.value = response.data;
      } catch (error) {
        console.error("Erro ao buscar transações de cartão:", error);
      }
    };

    const logout = async () => {
      await store.dispatch('setAuth', false);
      localStorage.removeItem("jwt");
      await router.push("/");
    };

    onMounted(() => {
      fetchTransacoesPix();
      fetchTransacoesCartao();
    });

    return {
      transacoesPix,
      transacoesCartao,
      logout
    };
  },
};
</script>

<style scoped>
/* Seus estilos aqui */
.dashboard-container {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 250px;
  padding-top: 20px;
}

.dalipay-bg {
  background-color: #006b5b;
}

.sidebar .nav-link {
  color: white;
  margin: 10px 0;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.sidebar .nav-link.active,
.sidebar .nav-link:hover {
  background-color: #004f45;
}

.content {
  flex-grow: 1;
  padding: 20px;
  background-color: #f8f9fa;
}

.header {
  background-color: #a7b9b1;
  color: white;
  padding: 15px;
  border-radius: 10px;
}

.logo {
  font-weight: bold;
  font-size: 1.5rem;
}

.table-responsive {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h5 {
  font-weight: 600;
  margin-bottom: 20px;
}

.badge {
  font-size: 0.9rem;
  padding: 0.5em;
  border-radius: 5px;
}

.sitename::before {
  content: ".dali";
  color: black;
}

.sitename::after {
  content: "pay";
  color: #009962;
}

.table-scroll {
  max-height: 300px; /* Defina a altura máxima para a área da tabela */
  overflow-y: auto;  /* Habilita o scroll vertical */
}

.table-responsive {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
</style>