<template>
  <div class="dashboard-container">
    <!-- Sidebar Esquerda -->
    <nav class="sidebar dalipay-bg">
      <ul class="nav flex-column">
        <li class="nav-item">
         <router-link to="/dashboard"> <a class="nav-link" aria-current="page" href="#">Dashboard</a></router-link>
        </li>
        <li class="nav-item">
          <router-link to="/relatorios"><a class="nav-link" href="#">Relatórios</a></router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link active" href="#">Configurações</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Ajuda</a>
        </li>
      </ul>
    </nav>

    <!-- Conteúdo à direita -->
    <main class="content">
      <!-- Header -->
      <header class="header d-flex justify-content-between align-items-center">
        <h1 class="logo"><span class="sitename"></span></h1>
        <div class="header-icons d-flex align-items-center">
          <button class="btn btn-outline-light" @click="logout">Logout</button>
        </div>
      </header>

      <!-- Conteúdo Principal - Blocos de Dados -->
      <div class="content-body">
        <div class="data-block-container">
          <!-- Dados da Empresa -->
          <div class="data-block">
            <h5>Dados da Empresa</h5>
            <p>Nome: {{ empresa.nomeEmpresa }}</p>
            <p>CNPJ: {{ empresa.cnpjEmpresa }}</p>
            <p>Telefone: {{ empresa.telefoneEmpresa }}</p>
            <p>Telefone Secundário: {{ empresa.telefoneSecundario }}</p>
          </div>

          <!-- Dados de Contato -->
          <div class="data-block">
            <h5>Contato</h5>
            <p>Representante: {{ empresa.representanteComercial }}</p>
            <p>Email Primário: {{ empresa.emailPrimario }}</p>
            <p>Email Secundário: {{ empresa.emailSecundario }}</p>
          </div>

          <!-- Dados Bancários -->
          <div class="data-block">
            <h5>Dados Bancários</h5>
            <p>Tipo de Conta: {{ empresa.dadosBancarios.tipoConta }}</p>
            <p>Agência: {{ empresa.dadosBancarios.agencia }}</p>
            <p>Conta: {{ empresa.dadosBancarios.conta }}-{{ empresa.dadosBancarios.digitoConta }}</p>
            <p>Chave Pix: {{ empresa.dadosBancarios.chavePix }}</p>
          </div>

          <!-- Dados do Admin -->
          <div class="data-block">
            <h5>Dados do Administrador</h5>
            <p>Login: {{ empresa.dadosUsuariosAdmin.login }}</p>
            <p>URL Webhook: {{ empresa.dadosUsuariosAdmin.urlWebhook || 'Não definido' }}</p>
            <p>Chave Webhook: {{ empresa.dadosUsuariosAdmin.chaveWebhook || 'Não definida' }}</p>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script lang="ts">
import { ref, onMounted } from "vue";
import axios from "axios";
import router from "@/router";
import {useStore} from "vuex";


export default {
  name: "EmpresaInfo",
  setup() {
    const empresa = ref({
      nomeEmpresa: "",
      cnpjEmpresa: "",
      telefoneEmpresa: "",
      telefoneSecundario: "",
      representanteComercial: "",
      emailPrimario: "",
      emailSecundario: "",
      dadosBancarios: {
        tipoConta: "",
        agencia: "",
        conta: "",
        digitoConta: "",
        chavePix: "",
      },
      dadosUsuariosAdmin: {
        login: "",
        urlWebhook: "",
        chaveWebhook: "",
      },
    });
    const store = useStore();

    const fetchEmpresaInfo = async () => {
      try {
        const response = await axios.get("/v1/admin/dados");
        empresa.value = response.data;
      } catch (error) {
        console.error("Erro ao buscar dados da empresa:", error);
      }
    };

    const logout = async () => {
      await store.dispatch("setAuth", false);
      localStorage.removeItem("jwt");
      await router.push("/");
    };

    onMounted(() => {
      fetchEmpresaInfo();
    });

    return {
      empresa, logout
    };
  },
};
</script>
<style scoped>
.dashboard-container {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 250px;
  padding-top: 20px;
}

.dalipay-bg {
  background-color: #006b5b;
}

.sidebar .nav-link {
  color: white;
  margin: 10px 0;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.sidebar .nav-link.active,
.sidebar .nav-link:hover {
  background-color: #004f45;
}

.content {
  flex-grow: 1;
  padding: 20px;
  background-color: #f8f9fa;
}

.content-body {
  padding-top: 20px; /* Espaço entre o header e os blocos */
}

.data-block-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
  justify-content: center; /* Centraliza os blocos horizontalmente */
}

.data-block {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}


.header {
  background-color: #a7b9b1;
  color: white;
  padding: 15px;
  border-radius: 10px;
}

.logo {
  font-weight: bold;
  font-size: 1.5rem;
}

.data-block-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

.data-block {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.data-block h5 {
  font-weight: 600;
  margin-bottom: 15px;
}
</style>