<script>
import { ref } from 'vue';
import logo from '@/assets/img/hero-img.svg';
import logo2 from '@/assets/img/integracao.png';
import logo3 from '@/assets/img/recebimentos.png';
import logo4 from '@/assets/img/pagamentos.png';
import logo5 from '@/assets/img/transacoes.png';
import '@/assets/css/main.css';
import 'swiper/swiper-bundle.css';
import router from "@/router";

export default {
  name: "SiteLanding",
  setup() {
    const isMobileNavActive = ref(false);
    const showModalCadastro = ref(false);
    const formData = ref({
      nomeSocial: '',
      email: '',
      processaCartao: false,
      processaBoleto: false,
      processaPix: false
      });

    const toggleMobileNav = () => {
      isMobileNavActive.value = !isMobileNavActive.value;
      if (isMobileNavActive.value) {
        document.body.classList.add('mobile-nav-active');
      } else {
        document.body.classList.remove('mobile-nav-active');
      }
    };

    const toggleModal = () => {
      router.push('/cadastrostep');
    };

    const closeModal = () => {
      showModalCadastro.value = false;
      console.log("Modal fechado:", showModalCadastro.value);
    };

    const enviaFormulario = () => {
      console.log('Dados sendo enviados', formData.value);
      closeModal();
      router.push('/cadastrostep')
    };

    return {
      isMobileNavActive,
      toggleMobileNav,
      toggleModal,
      closeModal,
      enviaFormulario,
      showModalCadastro,
      formData,
      logo,
      logo2,
      logo3,
      logo4,
      logo5
    };
  }
};
</script>

<template>
  <title>DaliPay | Pagamentos online</title>
  <header id="header" class="header fixed-top">

    <div class="topbar d-flex align-items-center">
      <div class="container d-flex justify-content-center justify-content-md-between">
        <div class="contact-info d-flex align-items-center">
          <i class="bi bi-envelope d-flex align-items-center text-black"><a class="text-black" href="mailto:dalipay@dalipay.solutions">dalipay@dalipay.solutions</a></i>
        </div>
        <div class="social-links d-none d-md-flex align-items-center">
          <a href="#" class="twitter text-black"><i class="bi bi-twitter-x"></i></a>
          <!-- <a href="#" class="facebook text-black"><i class="bi bi-facebook"></i></a> -->
          <!-- <a href="#" class="instagram text-black"><i class="bi bi-instagram"></i></a> -->
          <a href="#" class="linkedin text-black"><i class="bi bi-linkedin"></i></a>
        </div>
      </div>
    </div><!-- End Top Bar -->

    <div class="branding d-flex align-items-cente">

      <div class="container position-relative d-flex align-items-center justify-content-between">
        <a class="logo d-flex align-items-center">
          <h1 class="sitename"></h1>
        </a>

        <nav id="navmenu" class="navmenu" :class="{ 'mobile-nav-active': isMobileNavActive }">
          <ul>
            <!-- Instragram | Linkedin | GIT -->
            <li><a href="#integracao" @click="closeMobileNav">Integração</a></li>
            <li><a href="#recebimentos" @click="closeMobileNav">Recebimentos</a></li>
            <li><a href="#pagamentos" @click="closeMobileNav">Pagamentos</a></li>
            <li><a href="#transacoes" @click="closeMobileNav">Facilidades</a></li>
            <li><router-link to="/login">Login</router-link></li>
          </ul>
          <i class="mobile-nav-toggle d-xl-none bi bi-list" :class="isMobileNavActive ? 'bi-x' : 'bi-list'"
             @click="toggleMobileNav"></i>
        </nav>

      </div>

    </div>

  </header>

  <main class="main">

    <section id="hero" class="hero section">

      <div class="container position-relative">
        <div class="row gy-5 justify-content-between">
          <div class="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center text-black">
            <h2><span>Bem-vindo à </span><span class="sitename"></span></h2>
            <p class="text-black">Pagamentos rápidos, seguros e sem complicações. Seu dinheiro em boas mãos.</p>
            <div class="d-flex">
              <a @click="toggleModal" class="btn-get-started text-black">Cadastre-se</a>
            </div>
          </div>
          <div class="col-lg-5 order-1 order-lg-2">
            <img :src="logo" class="img-fluid" alt="Logo">
          </div>
        </div>
      </div>

      <div class="icon-boxes position-relative">
        <div class="container position-relative">
          <div class="row gy-4 mt-5">

            <div class="col-xl-3 col-md-6">
              <div class="icon-box">
                <div class="icon"><i class="bi bi-file-earmark-code"></i></div>
                <h4 class="title"><a href="#integracao" class="stretched-link">Integracão</a></h4>
              </div>
            </div><!--End Icon Box -->

            <div class="col-xl-3 col-md-6">
              <div class="icon-box">
                <div class="icon"><i class="bi bi-currency-exchange"></i></div>
                <h4 class="title"><a href="#recebimentos" class="stretched-link">Recebimentos</a></h4>
              </div>
            </div><!--End Icon Box -->

            <div class="col-xl-3 col-md-6">
              <div class="icon-box">
                <div class="icon"><i class="bi bi-diagram-3"></i></div>
                <h4 class="title"><a href="#pagamentos" class="stretched-link">Pix, Link e QR Code</a></h4>
              </div>
            </div><!--End Icon Box -->

            <div class="col-xl-3 col-md-6">
              <div class="icon-box">
                <div class="icon"><i class="bi bi-arrow-down-up"></i></div>
                <h4 class="title"><a href="#transacoes" class="stretched-link">Facilidade nas transações</a></h4>
              </div>
            </div><!--End Icon Box -->

          </div>
        </div>
      </div>

    </section>

    <section id="about" class="about section">

      <!-- Sobre -->
      <div class="container section-title">
        <h2>SOBRE</h2>
        <p>DaliPay é uma solução moderna e eficiente para gestão de pagamentos, projetada para simplificar e agilizar todas as etapas do processo de cobrança. Nossa plataforma oferece uma experiência de pagamento segura e intuitiva, possibilitando que empresas de todos os tamanhos possam focar no crescimento dos seus negócios, enquanto nós cuidamos dos seus recebimentos.</p>
      </div><!-- Sobre -->

      <div class="container">

        <!-- Integração -->
        <div id="integracao" class="row gy-5 justify-content-between">

          <div class="col-lg-5 order-2 order-lg-1">
            <img :src="logo2" class="img-fluid rounded-4 mb-4" alt="">

          </div>
          <div class="col-lg-6 order-1 order-lg-2 d-flex flex-column justify-content-center text-black">
            <h3 style="text-align: left;">Integrações.</h3>
            <p style="text-align: left;">Na DaliPay, entendemos que cada segundo conta. Por isso, nossas integrações via API foram desenvolvidas para serem rápidas e descomplicadas, permitindo que você conecte seu sistema à nossa plataforma em questão de minutos. Com uma documentação clara e suporte técnico especializado, você pode começar a criar cobranças e gerenciar pagamentos com facilidade, sem complicações técnicas.</p>
          </div>

        </div><!-- Integração -->

        <!-- Recebimentos -->
        <div id="recebimentos" class="row gy-5 justify-content-between">

          <div class="col-lg-6">
            <h3 style="text-align: left;">Recebimentos.</h3>
            <p style="text-align: left;">a DaliPay, sabemos que cada negócio é único. Por isso, oferecemos planos personalizados que atendem às suas necessidades específicas. Seja você uma pequena empresa em crescimento ou uma grande corporação, nossos planos são flexíveis e podem ser adaptados para oferecer o melhor custo-benefício. Escolha o plano que melhor se adequa ao seu perfil e desfrute de todos os benefícios que a DaliPay tem a oferecer, com transparência e sem surpresas.</p>
          </div>

          <div class="col-lg-5">
            <img :src="logo3" class="img-fluid rounded-4 mb-4" alt="">
          </div>

        </div><!-- Recebimentos -->

        <!-- Pagamentos -->
        <div id="pagamentos" class="row gy-5 justify-content-between">

          <div class="col-lg-5 order-2 order-lg-1">
            <img :src="logo4" class="img-fluid rounded-4 mb-4" alt="">

          </div>
          <div class="col-lg-6 order-1 order-lg-2 flex-column justify-content-center text-black">
            <h3 style="text-align: left;">Pagamentos</h3>
            <p style="text-align: left;">A segurança nas transações é a nossa prioridade máxima. DaliPay é certificado pelo padrão PCI DSS, garantindo que todos os dados sensíveis de pagamento sejam protegidos com o mais alto nível de segurança disponível. Com DaliPay, você pode ter a tranquilidade de que suas transações estão seguras e que seus recebimentos serão processados de forma confiável e eficiente.</p>
          </div>

        </div><!-- Pagamentos -->


        <!-- Transações -->
        <div id="transacoes" class="row gy-5 justify-content-between">

          <div class="col-lg-6">
            <h3 style="text-align: left;">Transações</h3>
            <p style="text-align: left;">Com DaliPay, criar cobranças recorrentes nunca foi tão simples. Nossa plataforma permite que você configure pagamentos mensais de maneira intuitiva, automatizando o processo e garantindo que você receba seus pagamentos na data certa, todos os meses. Seja qual for o seu modelo de negócios, a DaliPay facilita a gestão de suas finanças, permitindo que você se concentre no que realmente importa: fazer o seu negócio crescer.
            </p>
          </div>

          <div class="col-lg-5">
            <img :src="logo5" class="img-fluid rounded-4 mb-4" alt="">
          </div>

        </div><!-- Transações -->

      </div>

    </section>

    <!-- Serviçoes -->
    <section style="background-color: #F5F6F7;" id="services" class="services section">

      <!-- Section Title -->
      <div class="container section-title">
        <h2>SERVIÇOS</h2>
        <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p>
      </div><!-- End Section Title -->

      <div class="container">

        <div class="row gy-4">

          <div style="text-align: left;" class="col-lg-4 col-md-6">
            <div class="service-item  position-relative">
              <div class="icon">
                <i class="bi bi-activity"></i>
              </div>
              <h3>Alto Desempenho</h3>
              <p>Nossa API suporta milhares de transações de pagamentos simultânea</p>
            </div>
          </div><!-- End Service Item -->

          <div style="text-align: left;" class="col-lg-4 col-md-6">
            <div class="service-item position-relative">
              <div class="icon">
                <i class="bi bi-broadcast"></i>
              </div>
              <h3>Checagem das transações</h3>
              <p>Validamos todas as transações mediante a origem da transação.</p>
            </div>
          </div><!-- End Service Item -->

          <div style="text-align: left;" class="col-lg-4 col-md-6">
            <div class="service-item position-relative">
              <div class="icon">
                <i class="bi bi-easel"></i>
              </div>
              <h3>Facilidade na integração.</h3>
              <p>Temos um método muito fácil de integração com seu website, aplicativo ou página de vendas.</p>
            </div>
          </div><!-- End Service Item -->

          <div style="text-align: left;" class="col-lg-4 col-md-6">
            <div class="service-item position-relative">
              <div class="icon">
                <i class="bi bi-bounding-box-circles"></i>
              </div>
              <h3>Escalabilidade</h3>
              <p>Sua empresa consegue faz várias transações em várias plataformas.</p>
            </div>
          </div><!-- End Service Item -->

          <div style="text-align: left" class="col-lg-4 col-md-6">
            <div class="service-item position-relative">
              <div class="icon">
                <i class="bi bi-calendar4-week"></i>
              </div>
              <h3>Recebimentos</h3>
              <p>Programe seus recebimentos de acordo com sua necessidade, vocé pode programar, semanal e mensal.</p>
            </div>
          </div><!-- End Service Item -->

          <div style="text-align: left;" class="col-lg-4 col-md-6">
            <div class="service-item position-relative">
              <div class="icon">
                <i class="bi bi-chat-square-text"></i>
              </div>
              <h3>Suporte</h3>
              <p>Temos um serviço de suporte especializado em integração de pagamentos.</p>
            </div>
          </div><!-- End Service Item -->

        </div>

      </div>

    </section><!-- /Services Section -->

    <section style="padding: 50px 0px 70px; background-color: #ffff;" class="hero section">

      <div class="container">
        <div class="justify-content-between">

          <h2 style="text-align: center; font-size: 35px; padding: auto;" class="text-black"><span>Que tal tornar <span
              style="color: #009962;">mais fácil</span> a forma como você recebe pagamentos com </span><span
              class="sitename"></span>?</h2>
          <div>
            <a style="margin-top: 15px;" @click="toggleModal" class="btn-get-started text-black">Cadastre-se <strong>gratuitamente</strong></a>
          </div>

        </div>
      </div>

    </section>
  </main>
  <footer id="footer" class="footer">

    <div class="container footer-top">
      <div class="row gy-4">
        <div class="col-lg-5 col-md-12 footer-about">
          <a href="index.html" class="logo d-flex align-items-center">
            <span class="sitename"></span>
          </a>
          <p style="text-align: left;">Onde não processamos apenas pagamentos, mas também construímos confiança,
            agilidade e inovação em cada transação. Sua segurança e satisfação são nossa prioridade!.</p>
          <div class="social-links d-flex mt-4">
            <a href=""><i class="bi bi-twitter-x"></i></a>
            <!-- <a href=""><i class="bi bi-facebook"></i></a> -->
            <!-- <a href=""><i class="bi bi-instagram"></i></a> -->
            <a href=""><i class="bi bi-linkedin"></i></a>
          </div>
        </div>


        <div class="col-lg-2 col-6 footer-links">
          <h4 style="text-align: left;">Links</h4>
          <ul>
            <li><a href="#header">Início</a></li>
            <li><a href="#integracao">Integracão</a></li>
            <li><a href="#recebimentos">Recebimentos</a></li>
            <li><a href="#pagamentos">Pagamentos</a></li>
            <li><a href="#transacoes">Facilidades</a></li>
          </ul>
        </div>


        <div class="col-lg-2 col-6 footer-links">
          <h4 style="text-align: left;">DaliPay</h4>
          <ul>
            <li>
              <router-link to="/login">Login</router-link>
            </li>
            <li>
              <router-link to="/termosdeuso">Termos de Uso</router-link>
            </li>
            <li>
              <router-link to="/politicadeprivacidade">Política de Privacidade</router-link>
            </li>
          </ul>
        </div>

        <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
          <h4>Contato</h4>
          <p>R Doutor Oliveira Botelho, 9</p>
          <p>Niterói, 24360-500</p>
          <p>Rio de Janeiro</p>
          <p><strong>Email:</strong> <span>dali@dalitechsolutions.com</span></p>
        </div>

      </div>
    </div>

    <div class="container copyright text-center mt-4">
      <p>© <span>Copyright</span> <strong class="px-1 sitename"></strong> <span>Todos direitos reservados.</span></p>
      <div class="credits">
        Desenvolvido por <a style="color: black" href="mailto:dali@dalitechsolutions.com">DaliTechSolutions</a>
      </div>
    </div>


  </footer>
</template>


<style scoped>
.sitename::before {
  content: ".dali";
  color: black;
}

.sitename::after {
  content: "pay";
  color: #009962; /* cor verde */
}

a {
  text-decoration: none;
}

.btn-get-started {
  cursor: pointer;
}
</style>