<script>
import { ref } from 'vue';
import '@/assets/css/main.css';
import 'swiper/swiper-bundle.css';

export default {
  name: 'TermosdeUso',
  setup() {
    const isMobileNavActive = ref(false);

    const toggleMobileNav = () => {
      isMobileNavActive.value = !isMobileNavActive.value;
      if (isMobileNavActive.value) {
        document.body.classList.add('mobile-nav-active');
      } else {
        document.body.classList.remove('mobile-nav-active');
      }
    };

    const closeMobileNav = () => {
      if (isMobileNavActive.value) {
        isMobileNavActive.value = false;
        document.body.classList.remove('mobile-nav-active');
      }
    };

    return {
      isMobileNavActive,
      toggleMobileNav,
      closeMobileNav,
    };
  },
};
</script>

<template>
  <title>DaliPay | Termos de Uso</title>
  <header id="header" class="header fixed-top">

    <div class="topbar d-flex align-items-center">
      <div class="container d-flex justify-content-center justify-content-md-between">
        <div class="contact-info d-flex align-items-center">
          <i class="bi bi-envelope d-flex align-items-center text-black"><a class="text-black" href="mailto:dalipay@dalipay.solutions">dalipay@dalipay.solutions</a></i>
        </div>
        <div class="social-links d-none d-md-flex align-items-center">
          <a href="#" class="twitter text-black"><i class="bi bi-twitter-x"></i></a>
          <!-- <a href="#" class="facebook text-black"><i class="bi bi-facebook"></i></a> -->
          <!-- <a href="#" class="instagram text-black"><i class="bi bi-instagram"></i></a> -->
          <a href="#" class="linkedin text-black"><i class="bi bi-linkedin"></i></a>
        </div>
      </div>
    </div><!-- End Top Bar -->

    <div class="branding d-flex align-items-cente">

      <div class="container position-relative d-flex align-items-center justify-content-between">
        <a  class="logo d-flex align-items-center">
          <h1 class="sitename"></h1>
        </a>

        <nav id="navmenu" class="navmenu" :class="{ 'mobile-nav-active': isMobileNavActive }">
          <ul>
            <!-- Instragram | Linkedin | GIT -->
            <li><a href="#integracao" @click="closeMobileNav">Integração</a></li>
            <li><a href="#recebimentos" @click="closeMobileNav">Recebimentos</a></li>
            <li><a href="#pagamentos" @click="closeMobileNav">Pagamentos</a></li>
            <li><a href="#transacoes" @click="closeMobileNav">Facilidades</a></li>
            <li><a href="" @click="closeMobileNav">Login<i class="bi bi-person-circle"></i></a></li>
          </ul>
          <i class="mobile-nav-toggle d-xl-none bi bi-list" :class="isMobileNavActive ? 'bi-x' : 'bi-list'"
          @click="toggleMobileNav"></i>
        </nav>

      </div>

    </div>

  </header>

  <main class="main">



  </main>

  <footer id="footer" class="footer">

    <div class="container footer-top">
      <div class="row gy-4">
        <div class="col-lg-5 col-md-12 footer-about">
          <a href="index.html" class="logo d-flex align-items-center">
            <span class="sitename"></span>
          </a>
          <p style="text-align: left;">Onde não processamos apenas pagamentos, mas também construímos confiança, agilidade e inovação em cada transação. Sua segurança e satisfação são nossa prioridade!.</p>
          <div class="social-links d-flex mt-4">
            <a href=""><i class="bi bi-twitter-x"></i></a>
            <!-- <a href=""><i class="bi bi-facebook"></i></a> -->
            <!-- <a href=""><i class="bi bi-instagram"></i></a> -->
            <a href=""><i class="bi bi-linkedin"></i></a>
          </div>
        </div>


        <div class="col-lg-2 col-6 footer-links">
          <h4 style="text-align: left;">Links</h4>
          <ul>
            <li><a href="#header">Início</a></li>
            <li><a href="#integracao">Integracão</a></li>
            <li><a href="#recebimentos">Recebimentos</a></li>
            <li><a href="#pagamentos">Pagamentos</a></li>
            <li><a href="#transacoes">Facilidades</a></li>
          </ul>
        </div>


        <div class="col-lg-2 col-6 footer-links">
          <h4 style="text-align: left;">DaliPay</h4>
          <ul>
            <li><a href="#">Login</a></li>
            <li><a href="#">Termos de Uso</a></li>
            <li><a href="#">Política de Privacidade</a></li>
          </ul>

        </div>

        <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
          <h4>Contact Us</h4>
          <p>A108 Adam Street</p>
          <p>New York, NY 535022</p>
          <p>United States</p>
          <p class="mt-4"><strong>Phone:</strong> <span>+1 5589 55488 55</span></p>
          <p><strong>Email:</strong> <span>info@example.com</span></p>
        </div>

      </div>
    </div>

    <div class="container copyright text-center mt-4">
      <p>© <span>Copyright</span> <strong class="px-1 sitename"></strong> <span>Todos direitos reservados.</span></p>
      <div class="credits">
          Desenvolvido por <a style="color: black" href="mailto:dali@dalitechsolutions.com">DaliTechSolutions</a>
      </div>
    </div>

  </footer>
</template>


<style scoped>
.sitename::before {
  content: ".dali";
  color: black;
}

.sitename::after {
  content: "pay";
  color: #009962; /* cor verde */
}

a {
  text-decoration: none;
}
</style>