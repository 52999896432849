import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useStore } from 'vuex'; // Importa o Vuex Store

import TermosdeUso from "@/views/Site/Nav/TermosdeUso.vue";
import LoginCliente from "@/views/Admin/LoginCliente.vue";
import SiteLanding from "@/views/Site/Nav/SiteLanding.vue";
import CadastroStepToStep from "@/views/Site/CadastroStepToStep.vue";
import Dashboard from "@/views/Admin/Dashboard.vue";
import Relatorios from "@/views/Admin/Relatorios.vue";
import Configuracoes from "@/views/Admin/Configuracoes.vue";

const routes: Array<RouteRecordRaw> = [
    { path : '/', component: SiteLanding },
    { path: '/termosdeuso', component: TermosdeUso },
    { path: '/login', component: LoginCliente },
    { path: '/cadastrostep', component: CadastroStepToStep },
    {
        path: '/dashboard',
        component: Dashboard,
        meta: { requiresAuth: true }
    },
    {
        path: '/relatorios',
        component: Relatorios,
        meta: { requiresAuth: true }
    },
    {
        path:'/configuracoes',
        component: Configuracoes,
        meta: { requiresAuth: true }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    const store = useStore();
    if (to.matched.some(record => record.meta.requiresAuth)) {
        const jwt = store.state.jwt || localStorage.getItem('jwt');
        if (!jwt) {
            next('/login');
        } else {
            next();
        }
    } else {
        next();
    }
});
export default router;
