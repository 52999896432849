<script>
import axios from "axios";
import router from "@/router";


export default {
  name: 'CadastroStepToStep',
  data() {
    return {
      step: 1,
      formData: {
        nomeSocial: "",
        documentoFiscal: "",
        telefoneComercial: "",
        telefoneSecundario: "",
        pessoaContato: "",
        email: "",
        emailSecundario: "",
        processaCartao: false,
        processaBoleto: false,
        processaPix: false,
        endereco: {
          cep: "",
          rua: "",
          cidade: "",
          estado: ""
        },
        dadosBancarios: {
          tipoConta: "",
          agencia: "",
          conta: "",
          digitoConta: "",
          chavePix: ""
        },
        usuario: {
          usuarioSistema: "",
          passsword: ""
        }
      }
    };
  },
  methods: {
    nextStep() {
      if (this.step < 5) {
        this.step++;
      }
    },
    prevStep() {
      if (this.step > 1) {
        this.step--;
      }
    },
    submitForm() {
      axios.post('https://api.dalipay.solutions/v1/create/client', this.formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            alert('Cadastro realizado com sucesso');
            router.push('/login');
            alert('Aguarde até seu cadastro ser validado para efetuar o login!');
            console.log('Formulário enviado com sucesso!', response);
          })
          .catch(error => {
            console.error('Erro ao enviar formulário:', error);
          });
    }
  }
};
</script>

<template>
  <div class="container d-flex justify-content-center align-items-center vh-100">
    <div class="step-form bg-primary-custom p-5 rounded shadow-lg w-100" style="max-width: 700px;">

      <!-- Indicadores de Etapas -->
      <ul class="nav nav-pills mb-4 justify-content-center">
        <li class="nav-item">
          <a :class="['nav-link', step === 1 ? 'active' : '']" href="#">Etapa 1</a>
        </li>
        <li class="nav-item">
          <a :class="['nav-link', step === 2 ? 'active' : '']" href="#">Etapa 2</a>
        </li>
        <li class="nav-item">
          <a :class="['nav-link', step === 3 ? 'active' : '']" href="#">Etapa 3</a>
        </li>
        <li class="nav-item">
          <a :class="['nav-link', step === 4 ? 'active' : '']" href="#">Etapa 4</a>
        </li>
        <li class="nav-item">
          <a :class="['nav-link', step === 5 ? 'active' : '']" href="#">Etapa 5</a>
        </li>
      </ul>

      <!-- Etapas do Formulário -->

      <!-- Etapa 1: Informações Pessoais -->
      <div v-if="step === 1">
        <h4 class="mb-4 text-center text-white">.informações pessoais</h4>
        <form>
          <div class="row mb-3">
            <div class="col-md-12 text-start">
              <label for="nomeSocial" class="form-label text-white">Nome Social/Razão Social</label>
              <input v-model="formData.nomeSocial" id="nomeSocial" class="form-control"/>
            </div>
          </div>
          <div class="row mb-3 text-start align-content-center">
            <div class="col-md-12">
              <label for="documentoFiscal" class="form-label text-white">Documento Fiscal</label>
              <input v-model="formData.documentoFiscal" id="documentoFiscal" class="form-control"/>
            </div>
          </div>
          <div class="form-check form-check-inline mb-2">
            <input type="checkbox" v-model="formData.processaCartao" id="processaCartao" class="form-check-input"/>
            <label class="form-check-label text-white" for="processaCartao">Irá processar cartão?</label>
          </div>
          <div class="form-check form-check-inline mb-2">
            <input type="checkbox" v-model="formData.processaBoleto" id="processaBoleto" class="form-check-input"/>
            <label class="form-check-label text-white" for="processaBoleto">Irá processar boleto?</label>
          </div>
          <div class="form-check form-check-inline mb-4">
            <input type="checkbox" v-model="formData.processaPix" id="processaPix" class="form-check-input"/>
            <label class="form-check-label text-white" for="processaPix">Irá processar pix?</label>
          </div>
          <button @click="nextStep" type="button" class="btn btn-light w-50">Próximo</button>
        </form>
      </div>

      <!-- Etapa 2 : Dados para Contato -->
      <div v-if="step === 2">
        <h4 class="mb-4 text-center text-white">.dados para contato</h4>
        <form>
          <div class="row mb-3">
            <div class="col-md-4 text-start">
              <label for="telefoneComercial" class="form-label text-white">Telefone Comercial</label>
              <input v-model="formData.telefoneComercial" id="telefoneComercial" class="form-control"/>
            </div>
            <div class="col-md-4 text-start">
              <label for="telefoneSecundario" class="form-label text-white">Telefone Secundário</label>
              <input v-model="formData.telefoneSecundario" id="telefoneSecundario" class="form-control"/>
            </div>
            <div class="col-md-4 text-start">
              <label for="pessoaContato" class="form-label text-white">Pessoa de Contato</label>
              <input v-model="formData.pessoaContato" id="pessoaContato" class="form-control"/>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-6 text-start">
              <label for="email" class="form-label text-white">Email</label>
              <input v-model="formData.email" id="email" class="form-control"/>
            </div>
            <div class="col-md-6 text-start">
              <label for="emailSecundario" class="form-label text-white">Email Secundário</label>
              <input v-model="formData.emailSecundario" id="emailSecundario" class="form-control"/>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <button @click="prevStep" type="button" class="btn btn-secondary">Anterior</button>
            <button @click="nextStep" type="button" class="btn btn-light">Próximo</button>
          </div>
        </form>
      </div>


      <!-- Etapa 3: Endereço -->
      <div v-if="step === 3">
        <h4 class="mb-4 text-center text-white">.endereço</h4>
        <form>
          <div class="row mb-3">
            <div class="col-md-3 text-start">
              <label for="cep" class="form-label text-white">CEP</label>
              <input v-model="formData.endereco.cep" id="cep" class="form-control"/>
            </div>
            <div class="col-md-7 text-start">
              <label for="rua" class="form-label text-white">Endereço</label>
              <input v-model="formData.endereco.rua" id="rua" class="form-control"/>
            </div>
            <div class="col-md-2 text-start">
              <label for="estado" class="form-label text-white">Estado</label>
              <input v-model="formData.endereco.estado" id="estado" class="form-control"/>
            </div>
          </div>
          <div class="row mb-3 align-items-center">
            <div class="col-md-12 text-start">
              <label for="cidade" class="form-label text-white">Cidade</label>
              <input v-model="formData.endereco.cidade" id="cidade" class="form-control"/>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <button @click="prevStep" type="button" class="btn btn-secondary">Anterior</button>
            <button @click="nextStep" type="button" class="btn btn-light">Próximo</button>
          </div>
        </form>
      </div>

      <!-- Etapa 4: Dados Bancários -->
      <div v-if="step === 4">
        <h4 class="mb-4 text-center text-white">.dados para recebimentos</h4>
        <form>
          <div class="row mb-3">
            <div class="col-md-3 text-start">
              <label for="tipoConta" class="form-label text-white">Tipo de Conta</label>
              <select v-model="formData.dadosBancarios.tipoConta" id="tipoConta" class="form-control">
                <option value="corrente">Corrente</option>
                <option value="poupanca">Poupança</option>
              </select>
            </div>
            <div class="col-md-3 text-start">
              <label for="agencia" class="form-label text-white">Agência</label>
              <input v-model="formData.dadosBancarios.agencia" id="agencia" class="form-control"/>
            </div>
            <div class="col-md-3 text-start">
              <label for="conta" class="form-label text-white">Conta</label>
              <input v-model="formData.dadosBancarios.conta" id="conta" class="form-control"/>
            </div>
            <div class="col-md-3 text-start">
              <label for="digitoConta" class="form-label text-white">Dígito da Conta</label>
              <input v-model="formData.dadosBancarios.digitoConta" id="digitoConta" class="form-control"/>
            </div>
            <div class="col-md-12 text-start">
              <label for="chavePix" class="form-label text-white">Chave Pix</label>
              <input v-model="formData.dadosBancarios.chavePix" id="chavePix" class="form-control"/>
            </div>
          </div>
          <br/>
          <div class="d-flex justify-content-between">
            <button @click="prevStep" type="button" class="btn btn-secondary">Anterior</button>
            <button @click="nextStep" type="button" class="btn btn-light">Próximo</button>
          </div>
        </form>
      </div>

      <!-- Etapa 5: Usuário -->
      <div v-if="step === 5">
        <h4 class="mb-4 text-center text-white">.usuário</h4>
        <form>
          <div class="row mb-3">
            <div class="col-md-6 text-start">
              <label for="usuarioSistema" class="form-label text-white">Usuário do Sistema</label>
              <input v-model="formData.usuario.usuarioSistema" id="usuarioSistema" class="form-control"/>
            </div>
            <div class="col-md-6 text-start">
              <label for="password" class="form-label text-white">Senha</label>
              <input type="password" v-model="formData.usuario.passsword" id="password" class="form-control"/>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <button @click="prevStep" type="button" class="btn btn-secondary">Anterior</button>
            <button @click="submitForm" type="button" class="btn btn-success">Finalizar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>


<style scoped>

* {
  box-sizing: border-box;
}

body {
  background-color: white;
  margin: 0;
  padding: 0;
}

.step-form {
  background-color: #008374;
  color: white;
}

.form-control {
  background-color: white;
  color: #000;
}

.form-label {
  color: white;
}

.btn-light {
  background-color: #ffffff;
  color: #008374;
  border: none;
}

.btn-light:hover {
  background-color: #f8f9fa;
  color: #008374;
}

.nav-pills .nav-link {
  background-color: #008374;
  color: white;
  margin: 0 5px;
  border-radius: 50px;
  border: none;
}

.nav-pills .nav-link.active {
  background-color: #ffffff;
  color: #008374;
}

.btn-secondary {
  background-color: #6c757d;
  border: none;
}

.btn-success {
  background-color: #28a745;
  border: none;
}

.form-check-label {
  color: white;
}

</style>