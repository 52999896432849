<script lang="ts">
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import axios from "axios";// Usando o axios que já tem o interceptor
import router from "@/router";

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Login",
  setup() {
    const email = ref<string>("");
    const password = ref<string>("");
    const store = useStore();

    const handleLogin = async () => {
      try {
        const response = await axios.post(
            "https://api.dalipay.solutions/v1/oauth/token",
            new URLSearchParams({
              client_id: email.value,
              client_secret: password.value,
              grant_type: "client_credentials",
            }),
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "application/json",
              },
              withCredentials: true,
            }
        );
        const accessToken = response.data.access_token;
        store.commit('setAuth', true);
        localStorage.setItem("jwt", accessToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        await router.push('/dashboard');
      } catch (error) {
        console.error("Login error:", error);
        alert('Login failed');
      }
    };

    return {
      email,
      password,
      handleLogin,
    };
  },
});
</script>

<style scoped>

.sitename::before {
  content: ".dali";
  color: black;
}

.sitename::after {
  content: "pay";
  color: #009962; /* cor verde */
}

.login-container {
  background-color: #008374;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
}

.login-box {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.login-title {
  text-align: center;
  color: #008374;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 20px;
}

.btn-primary {
  background-color: #008374;
  border-color: #008374;
}

.btn-primary:hover {
  background-color: #006b5b;
  border-color: #006b5b;
}

.forgot-password {
  text-align: center;
  margin-top: 15px;
}

.text-light {
  color: #ffffff;
  text-decoration: underline;
}

a {
  text-decoration: none;
}

</style>

<template>
  <div class="login-container">
    <div class="login-box">
      <h1 class="login-title"><router-link to="/"><span class="sitename"></span></router-link></h1>
      <form @submit.prevent="handleLogin">
        <div class="form-group">
          <label for="email">Usuário: </label>
          <input
              v-model="email"
              type="text"
              class="form-control"
              id="usuarioEmail"
              placeholder="Insira seu usuário"
              required
          />
        </div>
        <div class="form-group">
          <label for="password">Senha: </label>
          <input
              v-model="password"
              type="password"
              class="form-control"
              id="password"
              placeholder="Insira sua senha"
              required
          />
        </div>
        <button type="submit" class="btn btn-primary btn-block">Login</button>
      </form>
      <div class="forgot-password">
        <a href="#" class="text-light">Forgot Password?</a>
      </div>
    </div>
  </div>
</template>
